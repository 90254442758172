<template>
  <span v-bind="$attrs" v-on="$listeners" :class="styleClasses">
    {{ text }}
  </span>
</template>

<script>
import { isNil, isFinite } from 'lodash';

export default {
  name: 'DataCellText',

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String],
    },

    parser: {
      type: Function,
    },

    thresholds: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    text: (vm) => (
      !isNil(vm.parser) && isFinite(vm.value)
        ? vm.parser(vm.value, { minimumFractionDigits: 2 })
        : vm.value || '-'
    ),

    color: (vm) => {
      const { lower, upper } = vm.thresholds;

      if (
        isNil(vm.value)
        || (isNil(lower) && isNil(upper))
      ) return '';

      if (
        !isNil(lower)
        && !isNil(upper)
        && vm.value >= lower
        && vm.value <= upper
      ) return 'amber';

      return vm.value >= (lower ?? upper) ? 'green' : 'red';
    },

    weight: (vm) => (isFinite(vm.value) ? 'bold' : 'regular'),

    scrollable: (vm) => (String(vm.value).length >= 60 ? 'scroll-on-hover' : ''),

    styleClasses: (vm) => [
      vm.color ? `${vm.color}--text` : '',
      `font-weight-${vm.weight}`,
      vm.scrollable,
    ],
  },
};
</script>
