<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
    :custom-sort="sortItems"
    item-key="_id"
  >
    <template v-slot:item.name="{ item }">
      <router-link class="link" :to="`/indexes/${item.indexId}`" target='_blank'>
        <fund-data-cell
          :value="item.shortName || item.name"
          :parser="formatQuota"
          :tooltip-items="{ 'Valor': item.value }"
        />
      </router-link>
    </template>

    <template v-slot:item.dailyChange="{ item }">
      <fund-data-cell
        :value="item.dailyChange"
        :parser="formatPercentage"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.wtd="{ item }">
      <fund-data-cell
        :value="item.wtd"
        :parser="formatPercentage"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.mtd="{ item }">
      <fund-data-cell
        :value="item.mtd"
        :parser="formatPercentage"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.ytd="{ item }">
      <fund-data-cell
        :value="item.ytd"
        :parser="formatPercentage"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.m6="{ item }">
      <fund-data-cell
        :value="item.m6"
        :parser="formatPercentage"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.m12="{ item }">
      <fund-data-cell
        :value="item.m12"
        :parser="formatPercentage"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.m24="{ item }">
      <fund-data-cell
        :value="item.m24"
        :parser="formatPercentage"
        :thresholds="{ lower: 0 }"
      />
    </template>{{ risk }}
    <template v-slot:item.volEWMA="{ item }">
      <fund-data-cell
        :value="annualize(item.volEWMA)"
        :parser="formatPercentage"
      />
    </template>
  </v-data-table>
</template>

<script>
import { get, isNil, orderBy } from 'lodash';
import { formatQuota, formatMoney, formatPercentage } from '@/utils/format-utils';
import { annualize } from '@/utils/metric-utils';

import FundDataCell from './FundDataCell.vue';

export default {
  name: 'BenchmarkTable',

  inheritAttrs: false,

  components: {
    FundDataCell,
  },

  data: () => ({
    headers: [
      {
        value: 'name',
        text: 'Nome',
        align: 'start',
      },
      {
        value: 'dailyChange',
        text: 'Dia',
        width: '96px',
        align: 'center',
      },
      {
        value: 'wtd',
        text: 'WTD',
        width: '96px',
        align: 'center',
      },
      {
        value: 'mtd',
        text: 'MTD',
        width: '96px',
        align: 'center',
      },
      {
        value: 'ytd',
        text: 'YTD',
        width: '96px',
        align: 'center',
      },
      { // Empty column to align with ReturnsTable component
        value: '',
        text: '',
        width: '96px',
        searchable: false,
        groupable: false,
        sortable: false,
      },
      {
        value: 'm6',
        text: '6M',
        width: '96px',
        align: 'center',
      },
      {
        value: 'm12',
        text: '12M',
        width: '96px',
        align: 'center',
      },
      {
        value: 'm24',
        text: '24M',
        width: '96px',
        align: 'center',
      },
      {
        value: 'volEWMA',
        text: 'Vol Anualizada',
        width: '120px',
        align: 'center',
      },
      { // Empty column to align with ReturnsTable component
        value: '',
        text: '',
        width: '120px',
        searchable: false,
        groupable: false,
        sortable: false,
      },
    ],
  }),

  methods: {
    formatQuota,
    formatPercentage,
    formatMoney,
    annualize,

    sortItems: (items, sortBy, sortDesc) => {
      const sortingFn = sortBy.map((key, index) => (item) => {
        const signal = sortDesc[index] ? -1 : 1;
        const value = get(item, key);

        return isNil(value) ? (signal * Infinity) : value;
      });

      const sortDir = sortDesc.map((val) => (val ? 'desc' : 'asc'));

      return orderBy(items, sortingFn, sortDir);
    },
  },
};
</script>
