<template>
  <v-card v-bind="$attrs" v-on="$listeners">
    <v-card-title>
      {{ details.reportName }}

      <v-switch dense hide-details v-model="vsBenchmark" class="ml-6 mt-0">
        <template v-slot:label>
          <span :class="vsBenchmarkTextColor">
            {{ vsBenchmarkText }}
          </span>
        </template>
      </v-switch>

      <v-spacer />

      <board-class-actions-menu
        @click:edit-funds="() => showEditFundsDialog()"
        @click:update="() => showUpdateTableDialog()"
        @click:delete="() => showDeleteTableDialog()"
      />
    </v-card-title>

    <v-card-text>
      <returns-table
        no-data-text="Nenhum fundo cadastrado"
        :mobile-breakpoint="0"

        sort-by="dailyChange"
        sort-desc
        must-sort

        :items-per-page="-1"
        hide-default-footer

        :items="tableItems"
      />
    </v-card-text>

    <v-dialog max-width="400" v-model="isUpdateTableDialogOpen">
      <v-card>
        <v-card-title>
          Editar quadro
        </v-card-title>

        <v-card-text class="py-0">
          <board-class-form
            ref="updateTableForm"
            discard-btn-text="Cancelar"
            :is-loading="isSendingData"
            :initial-data="details"

            @submit="(fields) => updateTable(fields)"
            @click:discard="() => hideUpdateTableDialog()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <contextual-dialog
      v-model="isDeleteTableDialogOpen"
      icon="mdi-delete"
      :icon-color="isSendingData ? 'grey' : 'error'"
      :loading="isSendingData"

      title="Deletar tabela?"
      :message="`Deseja realmente deletar a tabela '${details.reportName}'?`"
    >
      <v-card-actions class="justify-center">
        <v-btn color="primary" :disabled="isSendingData" @click="() => deleteTable()">
          Deletar
        </v-btn>
      </v-card-actions>
    </contextual-dialog>

    <v-dialog scrollable max-width="600" v-model="isEditFundsDialogOpen" persistent>
      <board-funds-editor
        ref="boardFundsEditor"
        :board-name="details.reportName"
        :current-items="details.fundsList"
        :loading="isSendingData"

        @click:close="() => hideEditFundsDialog()"
        @submit="(items) => updateBoardFunds(items)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { difference } from 'lodash';
import api from '@/services/api';

import { annualize, calculateSharpe } from '@/utils/metric-utils';
import { formatDate } from '@/utils/format-utils';

import ContextualDialog from '@/components/global/ContextualDialog.vue';

import BoardClassActionsMenu from './BoardClassActionsMenu.vue';
import BoardClassForm from './BoardClassForm.vue';
import BoardFundsEditor from './BoardFundsEditor.vue';
import ReturnsTable from './ReturnsTable.vue';

export default {
  name: 'ReturnsBoardClassCard',

  inheritAttrs: false,

  components: {
    ContextualDialog,
    BoardClassActionsMenu,
    BoardClassForm,
    BoardFundsEditor,
    ReturnsTable,
  },

  props: {
    details: {
      type: Object,
      required: true,
    },

    results: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    vsBenchmark: false,

    isSendingData: false,
    isUpdateTableDialogOpen: false,
    isDeleteTableDialogOpen: false,
    isEditFundsDialogOpen: false,
  }),

  computed: {
    vsBenchmarkText: (vm) => `${vm.details.indexRelative ? '%' : 'vs.'} ${vm.details.indexName}`,
    vsBenchmarkTextColor: (vm) => (vm.vsBenchmark ? 'primary--text' : 'grey--text lighten-2'),

    tableItems: (vm) => vm.results.map((item) => ({
      indexName: vm.details.indexName,

      name: item.name,
      startDate: formatDate(item.initialDate),
      fundId: item.fundId,
      quota: item.fundHistory?.quota,

      ...vm.vsBenchmark
        ? {
          tooltipText: 'Fundo',

          dailyChange: item.relativeHistory?.dailyChange,
          dailyChangeTooltip: item.fundHistory?.performance,

          wtd: item.relativeHistory?.wtd,
          wtdTooltip: item.fundHistory?.wtd,

          mtd: item.relativeHistory?.mtd,
          mtdTooltip: item.fundHistory?.mtd,

          ytd: item.relativeHistory?.ytd,
          ytdTooltip: item.fundHistory?.ytd,

          btd: item.relativeHistory?.btd,
          btdTooltip: item.fundHistory?.btd,

          m6: item.relativeHistory?.m6,
          m6Tooltip: item.fundHistory?.m6,

          m12: item.relativeHistory?.m12,
          m12Tooltip: item.fundHistory?.m12,

          m24: item.relativeHistory?.m24,
          m24Tooltip: item.fundHistory?.m24,
        }
        : {
          tooltipText: vm.vsBenchmarkText,

          dailyChange: item.fundHistory?.performance,
          dailyChangeTooltip: item.relativeHistory?.dailyChange,

          wtd: item.fundHistory?.wtd,
          wtdTooltip: item.relativeHistory?.wtd,

          mtd: item.fundHistory?.mtd,
          mtdTooltip: item.relativeHistory?.mtd,

          ytd: item.fundHistory?.ytd,
          ytdTooltip: item.relativeHistory?.ytd,

          btd: item.fundHistory?.btd,
          btdTooltip: item.relativeHistory?.btd,

          m6: item.fundHistory?.m6,
          m6Tooltip: item.relativeHistory?.m6,

          m12: item.fundHistory?.m12,
          m12Tooltip: item.relativeHistory?.m12,

          m24: item.fundHistory?.m24,
          m24Tooltip: item.relativeHistory?.m24,
        },

      dailyChangeBenchmark: item.benchmarkHistory?.dailyChange,
      wtdBenchmark: item.benchmarkHistory?.wtd,
      mtdBenchmark: item.benchmarkHistory?.mtd,
      ytdBenchmark: item.benchmarkHistory?.ytd,
      btdBenchmark: item.benchmarkHistory?.btd,
      m6Benchmark: item.benchmarkHistory?.m6,
      m12Benchmark: item.benchmarkHistory?.m12,
      m24Benchmark: item.benchmarkHistory?.m24,

      vol: item.fundHistory?.volEWMA,
      volAnnualized: annualize(item.fundHistory?.volEWMA),

      sharpe: calculateSharpe(
        item.fundHistory?.volEWMA,
        item.fundHistory?.performance,
        item.benchmarkHistory?.dailyChange,
      ),
      sharpeM12: calculateSharpe(
        annualize(item.fundHistory?.volEWMA),
        item.fundHistory?.m12,
        item.benchmarkHistory?.m12,
      ),
    })),
  },

  watch: {
    isUpdateTableDialogOpen() {
      this.$nextTick(() => this.$refs.updateTableForm?.discardChanges());
    },

    isEditFundsDialogOpen() {
      this.$nextTick(() => this.$refs.boardFundsEditor?.discardChanges());
    },
  },

  methods: {
    wipAlert() {
      this.$store.dispatch('alert/showAlert', {
        icon: 'mdi-tools',
        iconColor: 'warning',
        title: 'Work in progress',
        message: 'Ainda não implementamos essa funcionalidade.',
      });
    },

    // ------------
    //  Edit Table
    // ------------

    showUpdateTableDialog() {
      this.isUpdateTableDialogOpen = true;
    },

    hideUpdateTableDialog() {
      this.isUpdateTableDialogOpen = false;
    },

    async updateTable(data) {
      this.isSendingData = true;

      try {
        await api.returnsBoard.updateBoardClass(this.details._id, data);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Tabela atualizada',
          message: `A tabela "${data.reportName}" foi atualizada com sucesso!`,
        });

        this.$emit('refresh');
        this.hideUpdateTableDialog();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: `Não foi possível atualizar a tabela ${this.details.reportName}`,
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    // --------------
    //  Delete Table
    // --------------

    showDeleteTableDialog() {
      this.isDeleteTableDialogOpen = true;
    },

    hideDeleteTableDialog() {
      this.isDeleteTableDialogOpen = false;
    },

    async deleteTable() {
      this.isSendingData = true;

      try {
        await api.returnsBoard.deleteBoardClass(this.details._id);

        this.$emit('refresh');
        this.hideDeleteTableDialog();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: `Não foi possível remover a tabela "${this.details.reportName}"`,
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    // ------------
    //  Edit Funds
    // ------------

    showEditFundsDialog() {
      this.isEditFundsDialogOpen = true;
    },

    hideEditFundsDialog() {
      this.isEditFundsDialogOpen = false;
    },

    async updateBoardFunds(items) {
      this.isSendingData = true;

      try {
        const currentIds = this.details.fundsList.map((el) => el.fundId);
        const incomingIds = items.map((el) => el.fundId);

        const addedIds = difference(incomingIds, currentIds);
        const removedIds = difference(currentIds, incomingIds);

        // Remove API uses _id instead of fundId
        const trueRemovedIds = removedIds.map(
          (id) => this.details.fundsList.find((item) => item.fundId === id)?._id,
        );

        await Promise.all([
          ...addedIds.map((fundId) => api.returnsBoard.addFundToClass(this.details._id, fundId)),
          ...trueRemovedIds.map((fundId) => api.returnsBoard.removeFundFromClass(fundId)),
        ]);

        this.$emit('refresh');
        this.hideEditFundsDialog();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: `Não foi possível editar os fundos da tabela "${this.details.reportName}"`,
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },
  },
};
</script>
