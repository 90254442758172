var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"custom-sort":_vm.sortItems,"item-key":"_id"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":("/indexes/" + (item.indexId)),"target":"_blank"}},[_c('fund-data-cell',{attrs:{"value":item.shortName || item.name,"parser":_vm.formatQuota,"tooltip-items":{ 'Valor': item.value }}})],1)]}},{key:"item.dailyChange",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.dailyChange,"parser":_vm.formatPercentage,"thresholds":{ lower: 0 }}})]}},{key:"item.wtd",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.wtd,"parser":_vm.formatPercentage,"thresholds":{ lower: 0 }}})]}},{key:"item.mtd",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.mtd,"parser":_vm.formatPercentage,"thresholds":{ lower: 0 }}})]}},{key:"item.ytd",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.ytd,"parser":_vm.formatPercentage,"thresholds":{ lower: 0 }}})]}},{key:"item.m6",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.m6,"parser":_vm.formatPercentage,"thresholds":{ lower: 0 }}})]}},{key:"item.m12",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.m12,"parser":_vm.formatPercentage,"thresholds":{ lower: 0 }}})]}},{key:"item.m24",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.m24,"parser":_vm.formatPercentage,"thresholds":{ lower: 0 }}})]}},{key:"item.volEWMA",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":_vm.annualize(item.volEWMA),"parser":_vm.formatPercentage}})]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_vm._v(_vm._s(_vm.risk)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }