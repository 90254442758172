<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"

    :value="value"
    :items="sortedItems"
    :loading="loading"

    @focus="() => focusHandler()"
  />
</template>

<script>
import { debounce, isNil } from 'lodash';
import api from '@/services/api';

export default {
  name: 'ReturnsBoardSelector',

  inheritAttrs: false,

  // NOTE: "value" must be set as a prop for v-model to work properly
  // @see (https://github.com/vuejs/vue/issues/8430)
  props: {
    value: {
      type: [String, Array],
      default: null,
    },

    loadingColor: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isLoading: false,
    items: [],

    lastResponseDate: null,
  }),

  computed: {
    loading: (vm) => vm.isLoading && (vm.loadingColor || true),
    hasValue: (vm) => (Array.isArray(vm.value) ? vm.value.length > 0 : !isNil(vm.value)),
    sortedItems: (vm) => [...vm.items].sort((a, b) => (a.name > b.name ? 1 : -1)),
  },

  mounted() {
    if (this.hasValue) {
      this.fetchData();
    }
  },

  methods: {
    updateValue(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },

    focusHandler() {
      const MIN_INTERVAL = 10 * 60 * 1000;
      const duration = Date.now() - Date.parse(this.lastResponseDate);

      if (this.lastResponseDate && duration <= MIN_INTERVAL) {
        return;
      }

      this.fetchData();
    },

    fetchData: debounce(async function debouncedFetchData() {
      this.isLoading = true;

      try {
        const { data } = await api.returnsBoard.getBoardList();

        this.lastResponseDate = new Date();
        this.items = data.results;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isLoading = false;
      }
    }, 750),
  },
};
</script>
