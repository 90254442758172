<template>
  <v-tooltip bottom :disabled="isEmpty(tooltipItems)" transition="scale-transition">
    <template v-slot:activator="{ on }">
      <data-cell-text
        :value="value"
        :thresholds="thresholds"
        :parser="parser"
        v-on="on"
      />
    </template>
    <div v-if="!isEmpty(tooltipItems)">
      <span v-for="(val, key, idx) in tooltipItems" :key="idx">
        {{ key }}:
        <data-cell-text
          :value="val"
          :thresholds="thresholds"
          :parser="parser"
        />
        <br />
      </span>
    </div>
  </v-tooltip>
</template>

<script>
import { isEmpty } from 'lodash';

import DataCellText from './DataCellText.vue';

export default {
  name: 'FundDataCell',

  components: {
    DataCellText,
  },

  props: {
    value: {
      type: [Number, String],
    },

    parser: {
      type: Function,
    },

    thresholds: {
      type: Object,
      default: () => ({}),
    },

    tooltipItems: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    isEmpty,
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  background-color: #fff;
  color: gray;
  opacity: 1;
  box-shadow: 2px 2px 10px #00000020;
  border: solid 2px #eee;
  line-height: 20pt;
}
</style>
