<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
    :custom-sort="sortItems"
    item-key="_id"
  >
    <template v-slot:item.name="{ item }">
      <router-link class="link" :to="`/funds/${item.fundId}`" target='_blank'>
        <fund-data-cell
          :value="item.name"
          :parser="formatQuota"
          :tooltip-items="{ 'Cota': item.quota, 'Início': item.startDate }"
        />
      </router-link>
    </template>

    <template v-slot:item.dailyChange="{ item }">
      <fund-data-cell
        :value="item.dailyChange"
        :parser="formatPercentage"
        :tooltip-items="getTooltipData(item, 'dailyChange')"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.wtd="{ item }">
      <fund-data-cell
        :value="item.wtd"
        :parser="formatPercentage"
        :tooltip-items="getTooltipData(item, 'wtd')"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.mtd="{ item }">
      <fund-data-cell
        :value="item.mtd"
        :parser="formatPercentage"
        :tooltip-items="getTooltipData(item, 'mtd')"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.ytd="{ item }">
      <fund-data-cell
        :value="item.ytd"
        :parser="formatPercentage"
        :tooltip-items="getTooltipData(item, 'ytd')"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.btd="{ item }">
      <fund-data-cell
        :value="item.btd"
        :parser="formatPercentage"
        :tooltip-items="getTooltipData(item, 'btd')"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.m6="{ item }">
      <fund-data-cell
        :value="item.m6"
        :parser="formatPercentage"
        :tooltip-items="getTooltipData(item, 'm6')"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.m12="{ item }">
      <fund-data-cell
        :value="item.m12"
        :parser="formatPercentage"
        :tooltip-items="getTooltipData(item, 'm12')"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.m24="{ item }">
      <fund-data-cell
        :value="item.m24"
        :parser="formatPercentage"
        :tooltip-items="getTooltipData(item, 'm24')"
        :thresholds="{ lower: 0 }"
      />
    </template>

    <template v-slot:item.volAnnualized="{ item }">
      <fund-data-cell
        :value="item.volAnnualized"
        :parser="formatPercentage"
        :tooltip-items="{ 'Dia': item.vol }"
      />
    </template>

    <template v-slot:item.sharpeM12="{ item }">
      <fund-data-cell
        :value="item.sharpeM12"
        :tooltip-items="{ 'Dia': item.sharpe }"
        :thresholds="{ lower: 0, upper: 1 }"
        :parser="formatMoney"
      />
    </template>
  </v-data-table>
</template>

<script>
import { get, isNil, orderBy } from 'lodash';
import { formatQuota, formatMoney, formatPercentage } from '@/utils/format-utils';

import FundDataCell from './FundDataCell.vue';

export default {
  name: 'ReturnsTable',

  inheritAttrs: false,

  components: {
    FundDataCell,
  },

  data: () => ({
    headers: [
      {
        value: 'name',
        text: 'Nome',
        align: 'start',
      },
      {
        value: 'dailyChange',
        text: 'Dia',
        align: 'center',
        width: '96px',
      },
      {
        value: 'wtd',
        text: 'WTD',
        align: 'center',
        width: '96px',
      },
      {
        value: 'mtd',
        text: 'MTD',
        align: 'center',
        width: '96px',
      },
      {
        value: 'ytd',
        text: 'YTD',
        align: 'center',
        width: '96px',
      },
      {
        value: 'btd',
        text: 'BTD',
        align: 'center',
        width: '96px',
      },
      {
        value: 'm6',
        text: '6M',
        align: 'center',
        width: '96px',
      },
      {
        value: 'm12',
        text: '12M',
        align: 'center',
        width: '96px',
      },
      {
        value: 'm24',
        text: '24M',
        align: 'center',
        width: '96px',
      },
      {
        value: 'volAnnualized',
        text: 'Vol Anualizada',
        align: 'center',
        width: '120px',
      },
      {
        value: 'sharpeM12',
        text: 'Sharpe 12M',
        align: 'center',
        width: '120px',
      },
    ],
  }),

  methods: {
    formatQuota,
    formatPercentage,
    formatMoney,

    sortItems: (items, sortBy, sortDesc) => {
      const sortingFn = sortBy.map((key, index) => (item) => {
        const signal = sortDesc[index] ? -1 : 1;
        const value = get(item, key);

        return isNil(value) ? (signal * Infinity) : value;
      });

      const sortDir = sortDesc.map((val) => (val ? 'desc' : 'asc'));

      return orderBy(items, sortingFn, sortDir);
    },

    getTooltipData: (item, metric) => ({
      [item.indexName]: item[`${metric}Benchmark`],
      [item.tooltipText]: item[`${metric}Tooltip`],
    }),
  },
};
</script>
