var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"custom-sort":_vm.sortItems,"item-key":"_id"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":("/funds/" + (item.fundId)),"target":"_blank"}},[_c('fund-data-cell',{attrs:{"value":item.name,"parser":_vm.formatQuota,"tooltip-items":{ 'Cota': item.quota, 'Início': item.startDate }}})],1)]}},{key:"item.dailyChange",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.dailyChange,"parser":_vm.formatPercentage,"tooltip-items":_vm.getTooltipData(item, 'dailyChange'),"thresholds":{ lower: 0 }}})]}},{key:"item.wtd",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.wtd,"parser":_vm.formatPercentage,"tooltip-items":_vm.getTooltipData(item, 'wtd'),"thresholds":{ lower: 0 }}})]}},{key:"item.mtd",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.mtd,"parser":_vm.formatPercentage,"tooltip-items":_vm.getTooltipData(item, 'mtd'),"thresholds":{ lower: 0 }}})]}},{key:"item.ytd",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.ytd,"parser":_vm.formatPercentage,"tooltip-items":_vm.getTooltipData(item, 'ytd'),"thresholds":{ lower: 0 }}})]}},{key:"item.btd",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.btd,"parser":_vm.formatPercentage,"tooltip-items":_vm.getTooltipData(item, 'btd'),"thresholds":{ lower: 0 }}})]}},{key:"item.m6",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.m6,"parser":_vm.formatPercentage,"tooltip-items":_vm.getTooltipData(item, 'm6'),"thresholds":{ lower: 0 }}})]}},{key:"item.m12",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.m12,"parser":_vm.formatPercentage,"tooltip-items":_vm.getTooltipData(item, 'm12'),"thresholds":{ lower: 0 }}})]}},{key:"item.m24",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.m24,"parser":_vm.formatPercentage,"tooltip-items":_vm.getTooltipData(item, 'm24'),"thresholds":{ lower: 0 }}})]}},{key:"item.volAnnualized",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.volAnnualized,"parser":_vm.formatPercentage,"tooltip-items":{ 'Dia': item.vol }}})]}},{key:"item.sharpeM12",fn:function(ref){
var item = ref.item;
return [_c('fund-data-cell',{attrs:{"value":item.sharpeM12,"tooltip-items":{ 'Dia': item.sharpe },"thresholds":{ lower: 0, upper: 1 },"parser":_vm.formatMoney}})]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }