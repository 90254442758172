<template>
  <v-container fluid class="returns-board-details">
    <v-card outlined class="mb-4" color="primary" dark>
      <v-container fluid>
        <v-row>
          <v-col cols="auto">
            <v-btn
              icon
              :color="isFavorite ? 'yellow': 'grey'"
              @click.prevent="() => markAsFavorite(isFavorite ? null : boardId)"
            >
              <v-icon>
                {{ isFavorite ? 'mdi-star' : 'mdi-star-outline' }}
              </v-icon>
            </v-btn>
          </v-col>

          <v-col cols="3">
            <returns-board-selector
              ref="boardSelector"

              dense
              solo-inverted
              hide-details
              loading-color="#9d6c32"

              item-value="_id"
              item-text="name"

              :value="boardId"
              @change="(val) => changeBoardId(val)"
            />
          </v-col>

          <v-spacer />

          <v-col cols="3">
            <date-picker-field
              dense
              solo-inverted
              hide-details

              show-prev-next
              business-days-only
              :max-date="today"

              v-model="refDate"
            />
          </v-col>

          <v-col cols="auto">
            <board-actions-menu
              :disabled="!hasFoundData"

              @click:add-table="() => showAddTableDialog()"
              @click:rename="() => showEditBoardDialog()"
              @click:delete="() => showDeleteBoardDialog()"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-progress-linear
        absolute
        indeterminate
        color="#9d6c32"
        :active="isFetchingData"
      />
    </v-card>

    <template v-if="hasFoundData">
      <template v-for="item of results">
        <returns-board-class-card
          class="mb-4"
          :key="item.classData._id"
          :details="item.classData"
          :results="item.results"
          :loading="isFetchingData"

          @refresh="() => fetchData()"
        />
      </template>

      <v-card :loading="isFetchingData">
        <v-card-title>
          Benchmarks
        </v-card-title>

        <v-card-text>
          <benchmark-table
            :mobile-breakpoint="0"

            sort-by="dailyChange"
            sort-desc
            must-sort

            :items-per-page="-1"
            hide-default-footer

            :items="benchmarks"
          />
        </v-card-text>
      </v-card>
    </template>

    <template v-else-if="hasRequestedData">
      <empty-state
        icon="mdi-table-cancel"
        title="Quadro não encontrado"
        message="O quadro de retornos solicitado não pôde ser encontrado."
      >
        <v-btn color="primary" to="/returns/management">
          Voltar
        </v-btn>
      </empty-state>
    </template>

    <v-dialog max-width="400" v-model="isEditBoardDialogOpen">
      <v-card>
        <v-card-title>
          Editar quadro
        </v-card-title>

        <v-card-text class="py-0">
          <board-form
            ref="editBoardForm"
            discard-btn-text="Cancelar"
            :is-loading="isSendingData"
            :initial-data="details"

            @submit="(fields) => updateBoard(fields)"
            @click:discard="() => hideEditBoardDialog()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <contextual-dialog
      v-model="isDeleteBoardDialogOpen"
      icon="mdi-delete"
      :icon-color="isSendingData ? 'grey' : 'error'"
      :loading="isSendingData"

      title="Deletar quadro?"
      :message="`Deseja realmente deletar o quadro '${boardName}'?`"
    >
      <v-card-actions class="justify-center">
        <v-btn color="primary" :disabled="isSendingData" @click="() => deleteBoard()">
          Deletar
        </v-btn>
      </v-card-actions>
    </contextual-dialog>

    <v-dialog max-width="400" v-model="isAddTableDialogOpen">
      <v-card>
        <v-card-title>
          Adicionar tabela
        </v-card-title>

        <v-card-text class="py-0">
          <board-class-form
            ref="addTableForm"
            discard-btn-text="Cancelar"
            :is-loading="isSendingData"

            @submit="(fields) => addTable(fields)"
            @click:discard="() => hideAddTableDialog()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment-loyall';
import { debounce } from 'lodash';
import api from '@/services/api';

// eslint-disable-next-line import/no-cycle
import { loadValueFromUrl, saveValueOnUrl } from '@/utils/router-utils';

import ContextualDialog from '@/components/global/ContextualDialog.vue';
import DatePickerField from '@/components/global/DatePickerField.vue';
import EmptyState from '@/components/global/EmptyState.vue';

import BenchmarkTable from '../components/BenchmarkTable.vue';
import BoardActionsMenu from '../components/BoardActionsMenu.vue';
import BoardClassForm from '../components/BoardClassForm.vue';
import BoardForm from '../components/BoardForm.vue';
import ReturnsBoardSelector from '../components/ReturnsBoardSelector.vue';
import ReturnsBoardClassCard from '../components/ReturnsBoardClassCard.vue';

import { STORAGE_KEYS } from '../config';

export default {
  name: 'ReturnsBoardDetailsView',

  components: {
    ContextualDialog,
    DatePickerField,
    EmptyState,
    BenchmarkTable,
    BoardActionsMenu,
    BoardClassForm,
    BoardForm,
    ReturnsBoardSelector,
    ReturnsBoardClassCard,
  },

  props: {
    boardId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    refDate: loadValueFromUrl('refDate') ?? moment().prevBusinessDay('brasil').format('YYYY-MM-DD'),
    favoriteBoardId: localStorage.getItem(STORAGE_KEYS.favBoard),
    today: moment().format('YYYY-MM-DD'),

    hasRequestedData: false,
    isFetchingData: false,

    details: null,
    results: [],
    benchmarks: [],

    isSendingData: false,
    isEditBoardDialogOpen: false,
    isDeleteBoardDialogOpen: false,
    isAddTableDialogOpen: false,
  }),

  computed: {
    isFavorite: (vm) => vm.favoriteBoardId === vm.boardId,
    hasFoundData: (vm) => Object.keys(vm.details ?? {}).length > 0,
    boardName: (vm) => vm.details?.name,
  },

  watch: {
    favoriteBoardId(boardId) {
      if (boardId) {
        localStorage.setItem(STORAGE_KEYS.favBoard, boardId);
      } else {
        localStorage.removeItem(STORAGE_KEYS.favBoard);
      }
    },

    refDate(newValue) {
      saveValueOnUrl('refDate', newValue);

      this.resetData();
      this.fetchData();
    },

    boardId() {
      this.resetData();
      this.fetchData();
    },

    isEditBoardDialogOpen() {
      this.$nextTick(() => this.$refs.editBoardForm?.discardChanges());
    },

    isAddTableDialogOpen() {
      this.$nextTick(() => this.$refs.addTableForm?.discardChanges());
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    resetData() {
      this.hasRequestedData = false;

      this.details = null;
      this.results = [];
      this.benchmarks = [];
    },

    changeBoardId(newId) {
      const { hash, query } = this.$router.currentRoute;
      this.$router.replace({ path: `/returns/${newId}`, hash, query });
    },

    markAsFavorite(boardId) {
      this.favoriteBoardId = boardId;
    },

    fetchData: debounce(async function debouncedFetchData() {
      this.isFetchingData = true;

      try {
        const [detailsResponse, resultsResponse, benchmarksResponse] = await Promise.all([
          api.returnsBoard.getBoardDetails(this.boardId),
          api.returnsBoard.getBoardResults(this.boardId, this.refDate),
          api.indexes.returnsBoard({ params: { date: this.refDate } }),
        ]);

        this.details = detailsResponse.data;
        this.results = resultsResponse.data;
        this.benchmarks = benchmarksResponse.data;
      } catch (error) {
        if (error.response?.status === 404) {
          return;
        }

        this.$store.dispatch('alert/showAlert', {
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isFetchingData = false;
        this.hasRequestedData = true;
      }
    }, 400),

    // ------------
    //  Edit Board
    // ------------

    showEditBoardDialog() {
      this.isEditBoardDialogOpen = true;
    },

    hideEditBoardDialog() {
      this.isEditBoardDialogOpen = false;
    },

    async updateBoard(data) {
      this.isSendingData = true;

      try {
        await api.returnsBoard.updateBoard(this.boardId, data);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Quadro atualizado',
          message: `O quadro "${data.name}" foi atualizado com sucesso!`,
        });

        this.hideEditBoardDialog();
        this.fetchData();
        this.$refs.boardSelector?.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: `Não foi possível atualizar o quadro para "${data.name}"`,
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    // --------------
    //  Delete Board
    // --------------

    showDeleteBoardDialog() {
      this.isDeleteBoardDialogOpen = true;
    },

    hideDeleteBoardDialog() {
      this.isDeleteBoardDialogOpen = false;
    },

    async deleteBoard() {
      this.isSendingData = true;

      try {
        await api.returnsBoard.deleteBoard(this.boardId);

        if (this.isFavorite) {
          this.markAsFavorite(null);
        }

        this.$nextTick(() => this.$router.push('/returns/management'));
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: `Não foi possível remover o quadro "${this.boardName}"`,
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },

    // -----------
    //  Add Table
    // -----------

    showAddTableDialog() {
      this.isAddTableDialogOpen = true;
    },

    hideAddTableDialog() {
      this.isAddTableDialogOpen = false;
    },

    async addTable(fields) {
      this.isSendingData = true;

      try {
        await api.returnsBoard.addBoardClass(this.boardId, fields);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Tabela criada!',
          message: `Tabela "${fields.reportName}" criada com sucesso.`,
        });

        this.hideAddTableDialog();
        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: `Não foi possível adicionar a tabela "${fields.reportName}".`,
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table {
    th {
      white-space: nowrap;
      color: #fff !important;
      height: 36px !important;
      background-color: #18324f !important;

      i { color: #fff !important; }
    }

    tbody tr {
      &:nth-child(even) {
        background-color: #eee;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }
      &:hover {
        color: #18324f;
        background-color: #dfeaf0 !important;

        i { color: #18324f; }
      }
    }

    td {
      white-space: nowrap;
      border: none !important;
      height: 36px !important;
    }

    .scroll-on-hover {
      display: inline-block;

      &:hover {
        transform: translateX(-50%);
        transition: ease-in-out 3s;
      }
    }

    .link {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    // Freezes second column (fund name)
    th,
    td {
      &:nth-child(1):not(:only-child) {
        background-color: inherit;

        position: sticky;
        left: 0;
        z-index: 1;

        max-width: 160px;
        overflow: hidden;

        @media (min-width: 600px) {
          max-width: 300px;
        }
      }
    }
  }
}
</style>
