import { isNil } from 'lodash';

import Decimal from 'decimal.js';

export function annualize(val?: number | string | null) {
  if (isNil(val)) return val;

  const annualizationConstant = Decimal.sqrt(252);
  return new Decimal(val).times(annualizationConstant).toNumber();
}

export function calculateSharpe(
  volatility?: number | string | null,
  assetReturn?: number | string | null,
  benchReturn?: number | string | null,
) {
  if (isNil(volatility) || isNil(assetReturn) || isNil(benchReturn)) return null;

  return new Decimal(assetReturn).sub(benchReturn).div(volatility).toNumber();
}
