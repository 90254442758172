<template>
  <v-menu bottom left>
    <template v-slot:activator="vMenuScope">
      <v-btn icon v-bind="{ ...$attrs, ...vMenuScope.attrs }" v-on="{ ...$listeners, ...vMenuScope.on }">
        <v-icon>
          mdi-cog
        </v-icon>
      </v-btn>
    </template>

    <template v-slot:default>
      <v-list class="pa-0">
        <v-list-item @click="() => $emit('click:add-table')">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon left>
                mdi-table-plus
              </v-icon>

              Adicionar tabela
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="() => $emit('click:rename')">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon left>
                mdi-pencil
              </v-icon>

              Renomear quadro
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item @click="() => $emit('click:delete')">
          <v-list-item-content>
            <v-list-item-title class="error--text">
              <v-icon left color="error">
                mdi-delete
              </v-icon>

              Deletar quadro
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-menu>
</template>

<script>
export default {
  name: 'BoardActionsMenu',

  inheritAttrs: false,
};
</script>
