<template>
  <v-card v-bind="$attrs" v-on="$listeners">
    <v-card-title>
      Editar fundos
    </v-card-title>

    <v-card-subtitle v-if="boardName">
      Adicionar ou remover fundos da tabela "{{ boardName }}"
    </v-card-subtitle>

    <v-container fluid class="px-8">
      <v-row dense>
        <v-col>
          <funds-selector
            dense
            label="Procurar fundo..."
            :disabled="loading"

            item-text="shortName"
            item-value="_id"
            :item-disabled="checkDisabledItems"

            v-model="currentFund"
            return-object
            @change="(item) => addFundToList(item)"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-divider />

    <v-card-text class="pb-0" style="position: relative;">
      <v-overlay absolute color="white" v-if="loading" />

      <v-list dense>
        <v-list-item v-if="!hasItems">
          <v-list-item-content>
            <v-list-item-title>
              Nenhum fundo cadastrado
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-for="item of sortedItems">
          <v-list-item :key="item.fundId">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon size="18" color="error" class="mr-2" @click="() => removeFundFromList(item)">
                  mdi-delete
                </v-icon>

                <span style="vertical-align: middle;">
                  {{ item.reportName }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>

    <v-divider />

    <v-card-actions class="justify-end">
      <v-btn text color="error" :disabled="loading" @click="() => $emit('click:close')">
        Cancelar
      </v-btn>

      <v-btn color="primary" :disabled="loading" :loading="loading" @click="() => submitChanges()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FundsSelector from '@/components/global/FundsSelector.vue';

export default {
  name: 'BoardFundsEditor',

  inheritAttrs: false,

  components: {
    FundsSelector,
  },

  props: {
    boardName: {
      type: String,
      default: '',
    },

    currentItems: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: (vm) => ({
    currentFund: null,
    items: [...vm.currentItems],
  }),

  computed: {
    hasItems: (vm) => vm.items.length > 0,
    sortedItems: (vm) => [...vm.items].sort((a, b) => (a.reportName > b.reportName ? 1 : -1)),
  },

  methods: {
    checkDisabledItems(item) {
      const ids = this.items.map((el) => el.fundId);

      return ids.includes(item._id);
    },

    addFundToList(item) {
      this.items.push({
        fundId: item._id,
        reportName: item.shortName,
      });

      this.$nextTick(() => { this.currentFund = null; });
    },

    removeFundFromList(item) {
      const index = this.items.findIndex((el) => el.fundId === item.fundId);

      if (index === -1) {
        return;
      }

      this.items.splice(index, 1);
    },

    discardChanges() {
      this.items = [...this.currentItems];
    },

    submitChanges() {
      this.$emit('submit', [...this.items]);
    },
  },
};
</script>
