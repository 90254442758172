<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="isLoading"
    @submit.prevent="() => submitForm()"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Nome"
            hint="Campo obrigatório"
            persistent-hint

            v-model="formFields.name"
            :rules="[vRules.requiredField]"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end" v-if="!hideActions">
        <v-col cols="12" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="isLoading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || isLoading"
            :loading="isLoading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fillSchema from '@/utils/fill-schema';
import { requiredField } from '@/utils/validators';

const formSchema = {
  name: '',
};

export default {
  name: 'BoardForm',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: null,
    },

    submitBtnText: {
      type: String,
      default: null,
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData ?? {}),
    isFormValid: false,

    vRules: {
      requiredField,
    },
  }),

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText ?? 'Descartar alterações',
      submitBtn: vm.submitBtnText ?? 'Enviar',
    }),
  },

  methods: {
    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData ?? {});
    },

    submitForm() {
      this.$emit('submit', { ...this.formFields });
    },
  },
};
</script>
