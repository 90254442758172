<template>
  <v-container fluid class="returns-boards-management">
    <v-progress-linear
      top
      absolute
      indeterminate
      color="#9d6c32"
      :active="isFetchingData"
    />

    <h1 class="text-h5 mb-2">Gestão de Quadros de Retornos</h1>
    <p class="text-caption mb-8">Marque um quadro como favorito para ser redirecionado para ele na próxima vez que acessar a página.</p>

    <div style="max-width: 600px;">
      <v-card @click="() => showCreationDialog()">
        <v-card-title class="py-2 text-subtitle-1">
          Criar novo quadro

          <v-spacer />

          <v-btn icon disabled>
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-card-title>
      </v-card>

      <template v-if="isFetchingData && !hasRequestedData">
        <v-skeleton-loader
          class="my-4"
          type="card-heading"
          elevation="2"
          v-for="i in 4"
          :key="i"
        />
      </template>

      <template v-for="item of parsedResults">
        <v-card class="my-4" :to="`/returns/${item._id}`" :key="item._id">
          <v-card-title class="py-2 text-subtitle-1">
            {{ item.name }}

            <v-spacer />

            <v-btn
              icon
              :color="item.isFav ? 'yellow': 'grey'"
              @click.prevent="() => markAsFavorite(item.isFav ? null : item._id)"
            >
              <v-icon>
                {{ item.isFav ? 'mdi-star' : 'mdi-star-outline' }}
              </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </template>
    </div>

    <v-dialog max-width="400" v-model="isCreationDialogOpen">
      <v-card>
        <v-card-title>
          Criar quadro
        </v-card-title>

        <v-card-text class="py-0">
          <board-form
            ref="creationForm"
            discard-btn-text="Cancelar"
            :is-loading="isSendingInfo"

            @submit="(fields) => createItem(fields)"
            @click:discard="() => hideCreationDialog()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from '@/services/api';
import { STORAGE_KEYS } from '../config';

import BoardForm from '../components/BoardForm.vue';

export default {
  name: 'ReturnsBoardManagementView',

  components: {
    BoardForm,
  },

  data: () => ({
    favoriteBoardId: localStorage.getItem(STORAGE_KEYS.favBoard),

    isFetchingData: false,
    hasRequestedData: false,
    results: [],

    isCreationDialogOpen: false,
    isSendingInfo: false,
  }),

  computed: {
    parsedResults: (vm) => vm.results.map((item) => ({ ...item, isFav: item._id === vm.favoriteBoardId })),
  },

  watch: {
    favoriteBoardId(boardId) {
      if (boardId) {
        localStorage.setItem(STORAGE_KEYS.favBoard, boardId);
      } else {
        localStorage.removeItem(STORAGE_KEYS.favBoard);
      }
    },

    isCreationDialogOpen(newState) {
      if (!newState) {
        this.$nextTick(() => this.$refs.creationForm?.discardChanges());
      }
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.returnsBoard.getBoardList();

        this.results = data.results;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isFetchingData = false;
        this.hasRequestedData = true;
      }
    },

    markAsFavorite(boardId) {
      this.favoriteBoardId = boardId;
    },

    showCreationDialog() {
      this.isCreationDialogOpen = true;
    },

    hideCreationDialog() {
      this.isCreationDialogOpen = false;
    },

    async createItem(data) {
      this.isSendingInfo = true;

      try {
        await api.returnsBoard.createBoard(data);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Quadro criado',
          message: `O quadro "${data.name}" foi criado com sucesso!`,
        });

        this.hideCreationDialog();
        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          message: `Não foi possível criar o quadro "${data.name}"`,
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingInfo = false;
      }
    },
  },
};
</script>
